import {useEffect} from "react"
import Select from "react-select"
import {useInit} from "../../../providers/Init"
import VariantionPhotosForm from "./VariantionPhotosForm"
export default function ProductVariantionsForm({index, data, remove, update}) {
  const {groups} = useInit()

  const handleChange = async (newValue, action) => {
    switch (action.action) {
      case "select-option": {
        update(index, "add_tag", action.option.value)
        break
      }
      case "remove-value": {
        update(index, "remove_tag", action.removedValue.value)
        break
      }
      // default: {
      //   onChangePost("categoryIds", [...value.filter((o) => o.value !== action.removedValue.value)])
      //   break
      // }
    }
  }
  // useEffect(() => {
  //   return () => {}
  // }, [data])

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <a href="#_" onClick={() => remove(index)}>
            Remove
          </a>
        </div>
        <div className="card-body">
          <VariantionPhotosForm index={index} update={update} photos={data.photos} />
          <div className="row mb-4">
            <div className="col-sm-6">
              <label className="form-label">Main price</label>
              <input type="text" className="form-control" value={data.main_price} onChange={(e) => update(index, "main_price", e.target.value)} />
            </div>
            <div className="col-sm-6">
              <label className="form-label">Wholesale price</label>
              <input type="text" className="form-control" value={data.wholesale_price} onChange={(e) => update(index, "wholesale_price", e.target.value)} />
            </div>
          </div>

          {groups.map((g, key) => {
            let defaultTags = []
            if (data.tags.length > 0) {
              for (let i = 0; i < data.tags.length; i++) {
                let id = data.tags[i]
                let d = g.tags?.filter((t) => t.id === id).map((t) => ({value: t.id, label: `${t.name}${t.code ? " - " + t.code : ""}`}))

                if (d.length > 0) {
                  defaultTags = defaultTags.concat(d)
                }
              }
            }

      
            return (
              <div className="form-group mb-3" key={key}>
                <label className="form-label">{g.name}</label>
                <Select
                  // defaultValue={defaultTags}
                  value={defaultTags}
                  isMulti
                  name={g.slug}
                  options={g.tags?.map((t) => ({value: t.id, label: `${t.name}${t.code ? " - " + t.code : ""}`}))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
